.methImg{
  width: 40%;
}
.methImg1{
  width: 28%;
}
.p-math1{
  color: #545D7A;
  padding: 3rem 0px;
}
.p-math{
  color: #545D7A;
}