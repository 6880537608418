
#container{
  width:  100%; 
  height: 585px; 
  background-size: cover;
  /* background-position: center; */
  background-image: url('../../../img/01022.svg');
}
.header{
  background: #006F61;
  width:  100%; 
  height: 400px; 
}
.partenaire-back{
  background: #f5f5f5;
  width:  100%; 
  height: 150px; 
}
.content-meth{
  /* background: #f5f5f5; */
  width:  100%; 
  height: 1000px; 

}
.title-header{
  text-align: center;
  color: white;
  font-weight: 100;
  font-family: zabal demo;
  font-size: 20px;

}
.social-header{
  font-family: zabal demo extrabold;
}
.home-content{
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 10px;
}

.service-header{
  margin-top: 50px;

}
.slide{
  margin-top: 28px;
}
.Clients{
  max-width: 1600px;
  padding: 3rem 10px;
  margin-left: 20px;
}
.Clients-image{
  margin-left: 15px;
  text-align: 'center',
}

.image-slide{
  width:120px;
}
.image-slide1{
  width:100px;
}

/**/
.title-meth-top{
  margin-top: 90px;
  text-transform: uppercase;
  color: #D72A2A;
  font-size: 40px;
  font-family: zabal demo ;

}
.title-meth-top-2{
  text-transform: uppercase;
  font-size: 30px;
  color: #10182F;
  font-family: zabal demo extrabold;
}

.title-meth-top-2 span{
  color: #0085FF;
}

.contenttop{
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 10px;
}
.content-home{
  max-width: 1500px;
  margin: 0 auto;
  padding: 3rem 10px;
}
.imgmethtop{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.href-style{
  text-decoration: none;
}
.partenaires{
  background: #F6F6F6;
  width: 100%;
  height: 100px;
}

/*---------------------------------------*/
.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 25vh;
}

.slider {
	height: 110px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 90%;
}

.slider .slide-track {
	-webkit-animation: scroll 30s linear infinite;
	animation: scroll 30s linear infinite;
	display: flex;
	width: calc(250px * 15);
}
.imgmeth{
  width: 50%;
 }

@-webkit-keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-250px * 10));
	}
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-250px * 10));
	}
}

/*----------------------------------------------*/


@media (max-width: 992px) {
  .partenaire-back,.content-meth{
    background: #ffffff;
   }
   .imgmeth{
    width: 60%;
   }
}

@media (max-width: 990px) {
  .header{
    height: 700px; 
  }
}
@media (min-width: 1800px) {
  .content-meth{
    background: #ffffff;
    width:  100%; /*or 70%, or what you want*/
    height:100%; /*or 70%, or what you want*/
  
  } }

@media (min-width: 3000px) {
    .content-meth{
      background: #ffffff;
      width:  100%; /*or 70%, or what you want*/
      height:100% /*or 70%, or what you want*/
    
    }
}

@media (min-width: 2800px) {

  #container{

    height: 900px; /*or 70%, or what you want*/
  
  }
}



@media (min-width: 2800px) {

  #container{

    height: 900px; /*or 70%, or what you want*/
  
  }
}

@media (max-width: 820px) {
  #container{
    width:  100%; 
    /* background-position: center; */
    background-image: url('../../../img/background/glop2.png');
  }
}

@media (max-width: 540px) {
  #container{
    width:  100%; 
    background-size: cover;
    /* background-position: center; */
    background-image: url('../../../img/background/glop3.png');
  }
}
