.slick-next:before,
.slick-prev:before{
  color: #1FCD75!important;
} 

.content-slide-image{
  max-width: 1500px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content-slide{
  max-width: 1500px;
  margin: 0 auto;
  padding: 3rem 10px;
  }

.slide{
  width: 40%;
  margin-left: 430px;
}
.slide2{
  width:22%;
  margin-left: 550px;
}

.slide1{
  width:22%;
  margin-left: 550px;
}

.slide3{
  width:25%;
  margin-left: 550px;
}
.slide4{
  width:28%;
  margin-left: 560px;
}



@media (max-width:1130px) {

  .slide{
    margin-left: 50px;
  }
  .slide3{
    width:50%;
    margin-left: 150px;
  }

}

@media (max-width:820px) {
  .title-meth-top , .title-meth-top-2{
    font-size: 30px
  }
  .contenttop{
    padding: 2rem 10px;
  }

  .slide{
    width: 70%;
    margin-left: 50px;
  }
  .slide2{
    width: 60%;
    margin-left: 50px;
  }

}
