.container{
  display:flex;
  max-width: 60%;
  margin: auto;
}

.social-blog{
  margin: auto;
}

.count-title {
 font-size: 50px;
 font-weight: normal;
 margin-top: 10px;
 margin-bottom: 0;
 text-align: center;
 color: #fff;
}

.stats-text {
 font-size: 18px;
 font-weight: normal;
 margin-top: 10px;
 margin-bottom: 0;
 text-align: center;
 color: #fff;
}


@media (max-width: 992px) {
  
  .counter {
  margin-bottom: 40px;
  }
  .container{
    display:grid;
    max-width: 60%;
    margin: auto;
  }

}