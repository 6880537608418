.icon{
  width: 20%;
}

.Formaion{
  background: rgb(2,86,154);
  background: linear-gradient(135deg, rgba(2,86,154,1) 0%, rgba(53,171,92,1) 100%);
  height: 270px;
}
.contact-hashLink{
  text-decoration: none;
  color: azure;
}

@media (max-width: 580px) {
  .Formaion{
    height: 400px;
  }
  
}
